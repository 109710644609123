import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const myQuery = graphql`
  {
    strapiBusinessGrowth {
      helpText
      title
      shortDesc
      businessCard {
        id
        icon
        title
        subTitle
      }
    }
  }
`

const CounterArea = () => {
  const {
    strapiBusinessGrowth: { helpText, title, shortDesc, businessCard }
  } = useStaticQuery(myQuery)

  return (
    <div className='counter-area pt-100 pb-70'>
      <div className='container'>
        <div className='section-title text-center'>
          <span className='sp-color2'>{helpText}</span>
          <h2>{title}</h2>
          <p>{shortDesc}</p>
        </div>
        <div className='row pt-45'>
          {businessCard.map(business => {
            return (
              <div className='col-lg-3 col-6 col-md-3' key={business.id}>
                <div className='counter-another-content'>
                  <i className={business.icon} />
                  <h3>{business.title}</h3>
                  <span>{business.subTitle}</span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CounterArea
