import React from 'react'
import { Link } from 'gatsby'

const TalkArea = () => {
  return (
    <div className='talk-area ptb-100'>
      <div className='container'>
        <div className='talk-content text-center'>
          <div className='section-title text-center'>
            <span className='sp-color1'>Contact Us</span>
            <h2>We are always available for your Queries. Just let us know</h2>
          </div>
          <Link
            to='/contact'
            className='default-btn btn-bg-two border-radius-5'
          >
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  )
}

export default TalkArea
