import React from 'react'
import { Link } from 'gatsby'
import BannerSubSlider from './BannerSubSlider'

const BannerAreaThree = () => {
  return (
    <div className="banner-area-two">
      <div className="container-fluid">
        <div className="container-max">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="banner-content">
                <h1>Make Business Thrive with Our SaaS Products</h1>
                <p>
                  We build products and solutions that help people, businesses and institutions
                  achieve economic growth & social progress
                </p>
                <div className="banner-btn">
                  {/* <Link to="/about" className="default-btn btn-bg-two border-radius-50">
                    Learn More <i className="bx bx-chevron-right" />
                  </Link> */}
                  <Link to="/contact" className="default-btn btn-bg-one border-radius-50 ml-20">
                    Get A Quote <i className="bx bx-chevron-right" />
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="banner-img">
                <img
                  src="https://res.cloudinary.com/innoage/image/upload/v1667069249/product-banner_iyhlxu.png"
                  alt="Images"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <BannerSubSlider />
    </div>
  )
}

export default BannerAreaThree
