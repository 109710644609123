import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Loadable from '@loadable/component'
import { getScaledImage } from '../../helper/utilities'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const myQuery = graphql`
  {
    strapiCaseHeader {
      helpText
      title
    }
    allStrapiCaseStudies {
      nodes {
        id
        images {
          url
        }
        headerTitle
        tagOne
        tagTwo
        readBtn
        slug
      }
    }
  }
`

const options = {
  loop: true,
  margin: 30,
  responsiveClass: true,
  nav: false,
  dots: false,
  autoplay: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1024: {
      items: 3,
    },
  },
}

const ProductsSlider = () => {
  const {
    strapiCaseHeader: { helpText, title },
    allStrapiCaseStudies: { nodes: caseStudies },
  } = useStaticQuery(myQuery)

  return (
    <div className="case-study-area pt-100 pb-70">
      <div className="container-fluid p-0">
        <div className="section-title text-center">
          <span className="sp-color2">{helpText}</span>
          <h2>{title}</h2>
        </div>

        <OwlCarousel className="case-study-slider owl-theme pt-45" {...options}>
          {caseStudies.map((studyarea, index) => {
            return (
              <div className="case-study-item" key={index}>
                <Link to={`/products/${studyarea.slug}`}>
                  <img
                    src={getScaledImage(studyarea.images.url, 200)}
                    alt={studyarea.headerTitle}
                    height="200px"
                  />
                </Link>
                <div className="content">
                  <h3>
                    <Link to={`/products/${studyarea.slug}`}>{studyarea.headerTitle}</Link>
                  </h3>
                  <ul>
                    <li>
                      <Link to={`/products/${studyarea.slug}`}>{studyarea.tagOne}</Link>
                    </li>
                    <li>
                      <Link to={`/products/${studyarea.slug}`}>{studyarea.tagTwo}</Link>
                    </li>
                  </ul>
                  <Link to={`/products/${studyarea.slug}`} className="more-btn">
                    <i className={studyarea.readBtn} />
                  </Link>
                </div>
              </div>
            )
          })}
        </OwlCarousel>
      </div>
    </div>
  )
}

export default ProductsSlider
