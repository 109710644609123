import * as React from 'react'

import Layout from '../components/_App/layout'
import Seo from '../components/_App/seo'
import TopHeader from '../components/Common/TopHeader'
import Navbar from '../components/_App/Navbar'
import CounterArea from '../components/Common/CounterArea'
import ProductsSlider from '../components/Common/ProductsSlider'
import Footer from '../components/_App/Footer'
import TechnologyArea from '../components/Products/TechnologyArea'
import TalkArea from '../components/Common/TalkArea'
import BrandArea from '../components/Common/BrandArea'
import BannerAreaThree from '../components/Products/BannerAreaThree'

const Products = () => {
  return (
    <Layout>
      <Seo
        title='Products'
        description='We build products and solutions that help people, businesses and institutions achieve economic growth & social progress'
      />

      <TopHeader />

      <Navbar />

      <BannerAreaThree />

      <TechnologyArea />

      <ProductsSlider />

      <TalkArea />

      <CounterArea />

      <BrandArea />

      <Footer />
    </Layout>
  )
}

export default Products
