import React from 'react'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
  loop: true,
  margin: 30,
  responsiveClass: true,
  nav: false,
  dots: false,
  autoplay: true,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    1024: {
      items: 3,
    },
  },
}

const BannerSubSlider = () => {
  return (
    <div className="container">
      <OwlCarousel className="banner-sub-slider owl-theme" {...options}>
        <div className="banner-sub-item">
          <img
            src="https://res.cloudinary.com/innoage/image/upload/w_550,h_450,c_fill/medium_home_Slider_3_2b94f43013.jpg"
            alt="clients"
          />
          <div className="content">
            <h3>100+</h3>
            <span>Satisfied Clients</span>
          </div>
        </div>

        <div className="banner-sub-item">
          <img
            src="https://res.cloudinary.com/innoage/image/upload/w_550,h_450,c_fill/world_wtod7g.jpg"
            alt="world"
          />
          <div className="content">
            <h3>3+</h3>
            <span>Country Worldwide</span>
          </div>
        </div>

        <div className="banner-sub-item">
          <img
            src="https://res.cloudinary.com/innoage/image/upload/w_550,h_450,c_fill/medium_service_details_12116cb105.jpg"
            alt="Projects"
          />
          <div className="content">
            <h3>25+</h3>
            <span>Projects Delivered</span>
          </div>
        </div>
      </OwlCarousel>
    </div>
  )
}

export default BannerSubSlider
